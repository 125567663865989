import { pick } from 'lodash';
import _systemConfig from '../../system-config.json';
import _availableAnalysisForEachSystems from '../../avaliable-anlaysis.json';
import _systemListWithConfigAndGeo from '../../system-list-with-config-and-geo.json';
import { genIndexConfig } from './index';
import {
    OVERVIEW_PAGE, ACTIVITY_PAGE, OD_PAGE, MONTHLY_REPORT,
} from './static';
import { DataType, ShareProps } from '../../types/share';

const availableAnalysisForEachSystems: DataType['availableAnalysisForEachSystems'] = _availableAnalysisForEachSystems;
const systemConfig: DataType['systemConfig'] = _systemConfig;
const systemListWithConfigAndGeo: DataType['systemListWithConfigAndGeo'] = _systemListWithConfigAndGeo;

// The purpose of this function is to extract the required config for every page to reduce the size of `page-data.json`
const pickConfig = ({ page, config }) => {
    let field;
    switch (page) {
        case OVERVIEW_PAGE:
            field = ['reportType', 'dateRange', 'name', 'system'];
            break;
        case ACTIVITY_PAGE:
            field = ['reportType', 'center', 'system', 'name', 'heatZoom', 'heatmapConfig', 'dateRange'];
            break;
        case OD_PAGE:
            field = ['reportType', 'system', 'name', 'center', 'odZoom', 'dateRange'];
            break;
        default:
            throw new Error('sth wrong here');
    }
    return pick(config, field);
};

export const getSystemList = (locale): ShareProps['systemList'] => genIndexConfig(systemListWithConfigAndGeo, locale);
export const getSystemListWithConfigAndGeo = (): Array<any> => systemListWithConfigAndGeo;
export const getCityNum = () => Object.keys(systemConfig).length;
export const getAnalyzedMonth = () => systemListWithConfigAndGeo.filter((i) => i.reportType === MONTHLY_REPORT).length;
export const getAvailableAnalysis = (system: string) => availableAnalysisForEachSystems[system];
export const getSystemNameLocaleName = ({locale, system, year, month}) => {
    const config = systemListWithConfigAndGeo.filter((i) =>
        i.system === system && i.year == year && i.month == month
    )[0];
    if (config) {
        return config.name[locale];
    } else {
        throw new Error(`cannot find the system name with ${locale}, ${system}, ${year}, ${month}`)
    }
}
export const getConfig = ({
    system, year, month, locale, page,
}) => {
    const configWithGeo = systemListWithConfigAndGeo.filter((item) => {
        if (item.year === `${year}` && item.system === system) {
            if (month) {
                if (item.month === month) {
                    return true;
                }
            } else if (!item.month) {
                return true;
            }
        }
        return false;
    });
    const { config } = configWithGeo[0];
    return pickConfig({
        config: {
            ...config,
            reportType: config.type,
            name: config.name[locale],
            ...systemConfig[system],
        },
        page,
    });
};
export const getGeo = ({ system, year, month }) => {
    const configWithGeo = systemListWithConfigAndGeo.filter((item) => {
        if (item.year === `${year}` && item.system === system) {
            if (month) {
                if (item.month === month) {
                    return true;
                }
            } else if (!item.month) {
                return true;
            }
        }
        return false;
    });
    const { geo } = configWithGeo[0];
    return geo;
};
