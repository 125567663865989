import { defineMessage } from '@lingui/macro';
import locales from '../locales';

import {
    defaultLocale,
    ACTIVITY_PAGE,
    OD_PAGE,
    OVERVIEW_PAGE,
    INDEX_PAGE,
    ABOUT_PAGE,
    ERROR_PAGE_404,
    YEARLY_REPORT,
    zhHant,
    zhHans,
} from './static';
import {
    getSystemNameLocaleName,
} from './getPageProps';
import {
    activityUrl, overviewUrl, odUrl, aboutUrl, indexUrl, genFullSiteLink, error404Url,
} from './urls';
import {format} from './index';
import { genDefaultJsonLd } from './jsonld.js';

const seoTitle = {
    index: defineMessage({ id: 'seo.index.title' }),
    about: defineMessage({ id: 'seo.about.title' }),
    overview: defineMessage({ id: 'seo.overview.title' }),
    activity: defineMessage({ id: 'seo.activity.title' }),
    od: defineMessage({ id: 'seo.od.title' }),
    siteName: defineMessage({ id: 'site.name' }),
};

const seoDesc = {
    index: defineMessage({ id: 'seo.index.description' }),
    about: defineMessage({ id: 'seo.about.description' }),
    overview: defineMessage({ id: 'seo.overview.description' }),
    activity: defineMessage({ id: 'seo.activity.description' }),
    od: defineMessage({ id: 'seo.od.description' }),
};

const replaceTimeSystem = ({ system, time, text }) => {
    if (!text || !system || !time) {
        throw new Error(`sth wrong in replace time system, ${text}, ${system}, ${time}`);
    }
    return text.replace(/\[time\]/gi, time)
        .replace(/\[system\]/gi, system);
};

const appendCompanyName = ({ text, locale = defaultLocale }) => (text ? `${text} | ${locales[locale][`site.name`]}` : locales[locale][`site.name`]);

const genIndex = (locale = defaultLocale) => ({
    title: locales[locale][`seo.index.title`],
    subtitle: locales[locale][`seo.index.description`],
});

const genAnalysisPages = (locale = defaultLocale, {system, year, month, isYearlyReport}, page) => {
    const meta = genAnalyticsMeta({system, year, month, locale, isYearlyReport});
    const title = replaceTimeSystem({ system: meta.system, time: meta.time, text: locales[locale][`seo.${page}.title`]});
    const subtitle = replaceTimeSystem({ system: meta.system, time: meta.time, text: locales[locale][`seo.${page}.title`] });
    return {
        title: appendCompanyName({ text: title, locale }),
        subtitle,
    };
};

const genAbout = (locale = defaultLocale) => ({
    title: appendCompanyName({ text: locales[locale][`seo.about.title`], locale }),
    subtitle: locales[locale][`seo.about.description`],
});

const genDefault = (locale = defaultLocale) => ({
    title: appendCompanyName({ locale }),
    subtitle: locales[locale][`seo.index.description`],
});

const genHreflangForAnalysisPage = ({
    system, year, month, genUrl,
}) => ([
    {
        locale: 'x-default',
        url: genFullSiteLink(genUrl({
            locale: defaultLocale, system, year, month,
        })),
    },
    {
        locale: 'en',
        url: genFullSiteLink(genUrl({
            locale: defaultLocale, system, year, month,
        })),
    },
    {
        locale: zhHant,
        url: genFullSiteLink(genUrl({
            locale: zhHant, system, year, month,
        })),
    },
    {
        locale: zhHans,
        url: genFullSiteLink(genUrl({
            locale: zhHans, system, year, month,
        })),
    },
]);

const genHrefForNormalPage = ({ genUrl }) => ([
    {
        locale: 'x-default',
        url: genFullSiteLink(genUrl(defaultLocale)),
    },
    {
        locale: 'en',
        url: genFullSiteLink(genUrl(defaultLocale)),
    },
    {
        locale: zhHant,
        url: genFullSiteLink(genUrl(zhHant)),
    },
    {
        locale: zhHans,
        url: genFullSiteLink(genUrl(zhHans)),
    },
]);

const genAnalyticsMeta = ({
    system, year, month, locale, isYearlyReport
}) => {
    let time;
    if (isYearlyReport) {
        time = format({ date: new Date(year), formatStr: 'yyyy', locale });
    } else {
        time = format({ date: new Date(year, month - 1), locale });
    }
    return {
        system: getSystemNameLocaleName({
            locale, system, year, month
        }),
        time,
    }
}

const genSEO = ({
    page, locale, system, year, month, isYearlyReport,
}) => {
    let seo;
    const jsonld = genDefaultJsonLd();
    let hreflang;
    let pageUrl;
    switch (page) {
    case INDEX_PAGE:
        seo = genIndex(locale);
        hreflang = genHrefForNormalPage({ genUrl: indexUrl });
        pageUrl = indexUrl(locale);
        break;

    case ABOUT_PAGE:
        seo = genAbout(locale);
        hreflang = genHrefForNormalPage({ genUrl: aboutUrl });
        pageUrl = aboutUrl(locale);
        break;

    case OVERVIEW_PAGE:
        seo = genAnalysisPages(locale, {system, year, month, isYearlyReport}, 'overview');
        hreflang = genHreflangForAnalysisPage({
            system, year, month, genUrl: overviewUrl,
        });
        pageUrl = overviewUrl({
            locale, year, month, system,
        });
        break;

    case ACTIVITY_PAGE:
        seo = genAnalysisPages(locale, {system, year, month, isYearlyReport}, 'activity');
        hreflang = genHreflangForAnalysisPage({
            system, year, month, genUrl: activityUrl,
        });
        pageUrl = activityUrl({
            locale, year, month, system,
        });
        break;

    case OD_PAGE:
        seo = genAnalysisPages(locale, {system, year, month, isYearlyReport}, 'od');
        hreflang = genHreflangForAnalysisPage({
            system, year, month, genUrl: odUrl,
        });
        pageUrl = odUrl({
            locale, year, month, system,
        });
        break;

    case ERROR_PAGE_404:
        seo = genIndex(locale);
        hreflang = genHrefForNormalPage({ genUrl: error404Url });
        pageUrl = error404Url(locale);
        break;

    default:
        return {
            seo: genDefault(locale),
        };
    }

    return {
        ...seo,
        url: genFullSiteLink(pageUrl),
        jsonld,
        hreflang,
    };
};

export default genSEO;
