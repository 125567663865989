import React from 'react';
import SEO from './SEO';

const Layout = ({ children, className = '', seo, locale }) => (
    <>
        <SEO seo={seo} locale={locale} />
        <div className={className}>
            {children}
        </div>
    </>
);

export default Layout;
