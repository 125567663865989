import React from 'react';
import Head from 'next/head'
// import { t } from '@lingui/macro';
import genSEO from '../utils/seo';
import { defaultLocale, ogUrl } from '../utils/static';

const SEO = ({
    seo,
    script,
    noscript,
    locale = defaultLocale,
}) => {
    const {
        title, subtitle, url, jsonld, hreflang,
    } = genSEO({ ...seo, locale });

    return (
        <Head>
            <title>{title}</title>
            <meta name="description" content={subtitle} />

            {/* OpenGraph tags */}
            <meta property="og:url" content={url} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={subtitle} />
            <meta property="og:image" content={ogUrl} />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta property="og:type" content="website" />
            {hreflang && hreflang.map(item => <link key={item.locale} rel="alternate" href={item.url} hrefLang={item.locale} />)}
            {script ? (
                <script
                    key="script"
                    dangerouslySetInnerHTML={{ __html: script }}
                />
            ) : null}
            {noscript && (
                <noscript
                    key="noscript"
                    dangerouslySetInnerHTML={{ __html: noscript }}
                />
            )}
            {jsonld
                ? (
                    <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonld) }} />
                )
                : null}
        </Head>
    );
};

export default SEO;
