import React from 'react';
import Link from 'next/link';
// import { injectIntl } from 'react-intl';
// import { addTrailingSlash } from '../utils';

// const Link = ({
//     children, to, activeClassName, intl: { locale }, partiallyActive, spaLink = true, ...other
// }) => {
//     let path = to;
//     if (!/\/blog/.test(to)) {
//         path = locale === 'en' ? to : `/${locale}${to}`;
//     }
//     // Tailor the following test to your environment.
//     // This example assumes that any internal link (intended for Gatsby)
//     // will start with exactly one slash, and that anything else is external.
//     const internal = /^\/(?!\/)/.test(to);
//     // Use Gatsby Link for internal links, and <a> for others
//     if (internal && spaLink) {
//         return (
//             <GatsbyLink
//                 to={addTrailingSlash(path)}
//                 activeClassName={activeClassName}
//                 partiallyActive={partiallyActive}
//                 {...other}
//             >
//                 {children}
//             </GatsbyLink>
//         );
//     }
//     return (
//         <a href={path} {...other}>
//             {children}
//         </a>
//     );
// };

export default Link;
