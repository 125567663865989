const { isNumber } = require('lodash');
const path = require('path');
const {
    getDaysInMonth, parse, format, getYear, getMonth,
} = require('date-fns');
const compareAsc = require('date-fns/compareAsc');
const { en, zhCN, zhTW } = require('date-fns/locale');
const { YEARLY_REPORT, MONTHLY_REPORT } = require('./static');

const localeMapping = {
    en,
    'zh-hant': zhTW,
    'zh-hans': zhCN,
};

exports.stationIdToName = ({ id, geo, t }) => {
    if (id === 'all') {
        return t({ id: 'od.allstation' });
    }
    return geo[id].station;
};
exports.isMobile = () => {
    if (typeof window !== 'undefined') {
        const w = window;
        const d = document;
        const e = d.documentElement;
        const g = d.getElementsByTagName('body')[0];
        const x = w.innerWidth || e.clientWidth || g.clientWidth;
        if (x <= 768) return true;
    }

    return false;
};

exports.systemChartHeight = (isMobile) => {
    if (isMobile) return '100';
    return '200';
};

// 取得heatmap現在這個時間點是切割在哪個時間區段
exports.getTimeSeg = (currentTime, sortedTimelist) => {
    const { length } = sortedTimelist;
    let currentIndex = 0;
    for (const index in sortedTimelist) {
        const time = sortedTimelist[index];
        if (currentTime <= time) {
            currentIndex = index;
            break;
        }
    }
    currentIndex = parseInt(currentIndex, 10);
    const prevSeg = currentIndex === 0 ? false : sortedTimelist[currentIndex - 1];
    const currentSeg = sortedTimelist[currentIndex];
    const nextSeg = currentIndex === length - 1 ? false : sortedTimelist[currentIndex + 1];

    return {
        prevSeg,
        currentSeg,
        nextSeg,
    };
};

exports.monthTranslate = (locale, month) => {
    const monthEn = {
        1: 'Jan.',
        2: 'Feb.',
        3: 'Mar.',
        4: 'Apr.',
        5: 'May',
        6: 'Jun.',
        7: 'Jul.',
        8: 'Aug.',
        9: 'Sep.',
        10: 'Oct.',
        11: 'Nov.',
        12: 'Dec.',
    };
    if (locale === 'en') return monthEn[month];
    if (locale === 'zh-hant' || locale === 'zh-hans') return `${month}月`;
};


exports.weekdayTranslate = (locale, _wday, abbr = false) => {
    const wday = parseInt(_wday, 10);
    const weekDayEn = {
        0: 'Sunday',
        1: 'Monday',
        2: 'Tuesday',
        3: 'Wednesday',
        4: 'Thursday',
        5: 'Friday',
        6: 'Saturday',
    };

    const weekDayEnAbbr = {
        0: 'Sun.',
        1: 'Mon.',
        2: 'Tue.',
        3: 'Wed.',
        4: 'Thu.',
        5: 'Fri.',
        6: 'Sat.',
    };

    const weekDayZh = {
        0: '星期日',
        1: '星期一',
        2: '星期二',
        3: '星期三',
        4: '星期四',
        5: '星期五',
        6: '星期六',
    };

    if (locale === 'en') {
        if (abbr) return weekDayEnAbbr[wday];
        return weekDayEn[wday];
    }
    return weekDayZh[wday];
};

exports.format = ({ date, formatStr = 'yyyy MMMM', locale = 'en' }) => format(date, formatStr, {
    locale: localeMapping[locale],
});

exports.nFormatter = (num) => {
    if (num >= 1000000000) return `${(num / 1000000000).toFixed(1).replace(/\.0$/, '')}G`;
    if (num >= 1000000) return `${(num / 1000000).toFixed(1).replace(/\.0$/, '')}M`;
    if (num >= 1000) return `${(num / 1000).toFixed(1).replace(/\.0$/, '')}K`;
    return num;
};

exports.countTotalWeekdayInMonths = (dateRange) => {
    const week = {
        0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0,
    };
    dateRange.forEach((date) => {
        const { year, month } = date;
        for (let day = 1; day <= getDaysInMonth(new Date(year, month)); day += 1) {
            const thisDay = new Date(year, month, day).getDay();
            week[thisDay] += 1;
        }
    });
    return week;
};

exports.countTotalDayInMonths = (dateRange) => dateRange.reduce((prev, cur) => getDaysInMonth(new Date(cur.year, cur.month)) + prev, 0);

exports.toPercent = (num) => `${Math.round(num * 10000) / 100} %`;

// 123456 -> 123,456
exports.numberToSplitExpress = (_num) => {
    const num = isNumber(_num) ? _num : parseInt(_num, 10);
    return num.toLocaleString('en-US');
};

exports.strPad = (n) => String(`00${n}`).slice(-2);

exports.outputYYMMDD = (time) => {
    const yy = time.getUTCFullYear();
    const mm = time.getUTCMonth() + 1;
    const dd = time.getUTCDate();
    return `${yy}-${mm}-${dd}`;
};

exports.capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

exports.genYYYYMM = (year, month) => (month ? `${year}${month}` : `${year}`);
const YYYYMMtoYearMonth = (YYYYMM) => {
    let date = parse(YYYYMM, 'yyyyM', new Date());

    // if is true, means the date format is possibly be `yyyy`
    if (isNaN(date)) {
        date = parse(YYYYMM, 'yyyy', new Date());
        return {
            year: `${getYear(date)}`,
        };
    }
    return {
        year: `${getYear(date)}`,
        month: `${getMonth(date) + 1}`,
    };
};
exports.YYYYMMtoYearMonth = YYYYMMtoYearMonth;

exports.genLink = (lang, link) => {
    if (lang === 'en') return link;
    return path.join(`/${lang}`, link);
};

exports.stopRunningTranslate = (lang) => {
    if (lang === 'en') return 'Stop operation';
    return '停運';
};

const withoutTrailing = (url) => url.slice(-1) !== '/';

exports.addTrailingSlash = (url) => {
    if (withoutTrailing(url)) {
        return `${url}/`;
    }
    return url;
};

exports.sortAscending = (a, b) => {
    const aCount = parseInt(a.count, 10);
    const bCount = parseInt(b.count, 10);
    if (aCount < bCount) return 1;
    if (aCount > bCount) return -1;
    return 0;
};

exports.sortDescending = (a, b) => {
    const aCount = parseInt(a, 10);
    const bCount = parseInt(b, 10);
    if (aCount < bCount) return -1;
    if (aCount > bCount) return 1;
    return 0;
};

const sortNumberAscending = (a, b) => {
    const aInt = parseInt(a, 10);
    const bInt = parseInt(b, 10);
    if (aInt < bInt) return -1;
    if (aInt > bInt) return 1;
    return 0;
};

exports.getWindowWidth = () => typeof window !== 'undefined' && (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth);
exports.getWindowHeight = () => typeof window !== 'undefined' && (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight);

exports.dateRangeToInt = (dateRange) => dateRange.map((item) => ({ year: parseInt(item.year, 10), month: parseInt(item.month, 10) }));
exports.sortDateRange = (dateRange) => dateRange.sort((a, b) => compareAsc(new Date(a.year, a.month), new Date(b.year, b.month)));

exports.genIndexConfig = (systemListWithConfigAndGeo, locale) => {
    const results = {
        months: 0,
        cities: 0,
        countries: {},
    };
    systemListWithConfigAndGeo.forEach((item) => {
        const {
            system, name, country, year, reportType, geo,
        } = item;
        if (reportType === YEARLY_REPORT) {
            if (!results.countries[country]) {
                results.countries[country] = {};
            }
            if (!results.countries[country][system]) {
                results.countries[country][system] = {
                    name: name[locale],
                    year,
                    stationNum: Object.keys(geo).length,
                };
            }
            if (results.countries[country][system].year < year) {
                results.countries[country][system].year = year;
            }
        } else if (reportType === MONTHLY_REPORT) {
            results.months += 1;
        }
    });
    return results;
};

exports.genAvailableAnalysisForEachSystems = (systemListWithConfigAndGeo) => {
    const tmpResults = {};
    const results = {};
    systemListWithConfigAndGeo.forEach((item) => {
        const {
            system, year, month, reportType,
        } = item;
        if (!tmpResults[system]) {
            tmpResults[system] = {};
        }
        if (!tmpResults[system][year]) {
            tmpResults[system][year] = [];
        }
        if (reportType === MONTHLY_REPORT) {
            tmpResults[system][year].push(month);
        }
    });
    // sort month and year
    Object.keys(tmpResults).forEach((system) => {
        results[system] = [];
        Object.keys(tmpResults[system]).forEach((year) => {
            const month = tmpResults[system][year].sort(sortNumberAscending);
            results[system].push({ year, month });
        });
    });

    return results;
};

exports.isDomAvailable = () => typeof window !== 'undefined' && !!window.document && !!window.document.createElement;
