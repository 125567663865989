import urlJoin from 'url-join';
import {
    siteUrl, OVERVIEW_PAGE, ACTIVITY_PAGE, OD_PAGE,
} from './static';


const genYYYYMMUrl = ({
    page, system, year, month, locale,
}) => {
    let url;
    year = `${year}`;
    if (month) {
        month = `${month}`;
        url = urlJoin('/', system, page, year, month, '/');
    } else {
        url = urlJoin('/', system, page, year, '/');
    }
    if (locale && locale !== 'en') {
        return urlJoin('/', locale, url, '/');
    }
    return url;
};

export const activityUrl = ({
    locale, system, year, month,
}) => genYYYYMMUrl({
    page: '/activity', system, year, month, locale,
});

export const overviewUrl = ({
    locale, system, year, month,
}) => genYYYYMMUrl({
    page: '/overview', system, year, month, locale,
});

export const odUrl = ({
    locale, system, year, month,
}) => genYYYYMMUrl({
    page: '/od', system, year, month, locale,
});

export const indexUrl = locale => (locale === 'en' ? '/' : urlJoin('/', locale, '/'));
export const aboutUrl = locale => (!locale || locale === 'en' ? '/about/' : urlJoin('/', locale, '/about/'));
export const error404Url = locale => (locale === 'en' ? '/404' : urlJoin('/', locale, '/404'));

export const genUrlBasedOnPage = ({
    locale, system, year, month, page,
}) => {
    switch (page) {
        case OVERVIEW_PAGE:
            return overviewUrl({
                locale, system, year, month,
            });
        case ACTIVITY_PAGE:
            return activityUrl({
                locale, system, year, month,
            });
        case OD_PAGE:
            return odUrl({
                locale, system, year, month,
            });
        default:
            throw new Error('sth wrong in url based on page');
    }
};

export const genFullSiteLink = link => urlJoin(siteUrl, link);
